import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import {
  SplitContent,
  SplitFigure,
  Container,
  SplitSection,
  ReadMore,
  Text
} from '../components/styles'
import Arrow from '../components/Arrow'
import styled from 'styled-components'
import Hero from '../components/Hero'
import { AboutContact } from '.'
import FAQs from '../components/FAQs'
import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 30vh;
  text-align: center;
  max-width: 600px;
  h3 {
    color: ${props => props.theme.colors.dark};
    text-shadow: none;
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="dark">
    <Helmet>
      <title>
        About Brisbane & Sunshine Coast’s Most Trusted Builders | Just Patios
      </title>
      <meta
        name="description"
        content="Operating for more than 15 years, Just Patios are highly experienced builder of patios, decks, carports & room ensclosures for residential & commercial properties. Find out more about us & call us for a free design & quote. "
      />
    </Helmet>

    <Container style={{ marginTop: 70 }} padY={true} medium={true}>
      <SplitSection>
        <SplitFigure className="split-about">
          <Img
            // style={{ marginTop: 50 }}
            fluid={data.team.childImageSharp.fluid}
            alt="Just Patios team"
          />
          {/* <Img
            style={{ marginTop: 50 }}
            fluid={data.sewing.childImageSharp.fluid}
            alt="GFit Gavin"
          /> */}
        </SplitFigure>
        <SplitContent>
          <h1 style={{ fontSize: '2.3rem' }}>About Us - Just Patios</h1>
          <p>
            Just Patios has been privately owned and operated since 2002. The
            company is very customer focused and strives for a high level of
            workmanship using quality products backed with warranty. Just Patios
            is very proud of its dedicated and loyal team of employees and
            contractors. All members take great pride and care in their work and
            strive daily on perfection, so rest assured you are dealing with
            true professionals in the field. Over the years Just Patios has
            built thousands of custom designed and engineered patios, carports,
            and room enclosure structures, all ranging from the very simple to
            the more complex.
          </p>
          <p>
            Just Patios holds a current building license with the QBCC and is
            fully insured. We are also long serving members of the Master
            Builders and are registered with Queensland Government Building and
            Asset Services.
          </p>
          <p>
            We currently and proudly sponsor local sporting adult and children
            teams in the local community and have also done so in the past.
          </p>
          <p>
            Just Patios continues to grow and will keep servicing the Brisbane
            and Sunshine Coast regions for many more years to come.
          </p>
          <h4>Why Choose Just Patios</h4>
          <p>
            Just Patios is committed to addressing clients’ needs and exceeding
            their expectations. We are all ears at every touchpoint, making sure
            that we fully understand our clients’ requirements and execute with
            quality.
          </p>

          <h4>Superior Expertise and Professionalism</h4>
          <p>
            Just Patios owners have over 20 years of building experience and
            have catered both residential and commercially throughout Brisbane
            and the Sunshine Coast. We aim to get your new project and turn it
            into reality for you to enjoy as soon as possible. Our builders are
            licensed and follow a strict code of conduct so we maintain the high
            standard you expect.
          </p>
          <h4>Beyond Doing Builds</h4>
          <p>
            Just Patios goes beyond doing builds. We also acquire building
            approvals for our clients. We prepare a site plan, lodge paperwork,
            work with private certifiers and coordinate with the local council
            on behalf of our clients. Contact us today for questions on council
            approvals or for your free design and quote.
          </p>

          <ReadMore to="/contact-us">
            Contact Just Patios Today
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
    </Container>

    <Flex id="team" justifyContent="center" style={{ marginBottom: 30 }}>
      <h1>Our Team</h1>
    </Flex>
    <Container
      style={{ borderRadius: 4 }}
      bg="#fdf6ee"
      padY={true}
      medium={true}
    >
      <SplitSection style={{ marginLeft: 0 }}>
        <SplitFigure>
          <Img fluid={data.sewing.childImageSharp.fluid} alt="GFit Gavin" />
        </SplitFigure>
        <SplitContent>
          <h5>Meet our Office &amp; Sales Team</h5>
          <p>
            We have very experienced office and sales staff to ensure your
            project is handled professionally. From the moment you call the
            office or drop by, you will feel like you are dealing with the right
            people. Our highly trained sales representatives will assist and
            guide you as well as listen to your needs to help achieve your
            dream. Our office staff are also trained in council applications and
            regulations ensuring what you want is going to achieve council
            approval. We also have a qualified supervisor to oversee your
            project ensuring only quality workmanship. So feel free to call our
            office or drop by and see us so we can help you with your new
            project.
          </p>

          <ReadMore to="/contact-us">
            Contact the team
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
      <Box justifyContent="center" mt={5}>
        <h3>
          Contact our crew today, they set a standard second to none in the
          industry.
        </h3>
      </Box>
    </Container>

    <Box mt={6} />

    <Testimonials testimonials={allTestimonials} />

    <AboutContact data={data} />

    <CTA
      title="Looking for a builder?"
      subtitle="We’re here to help you."
      button="Contact Us"
    />
  </Layout>
)

export const query = graphql`
  query AboutPageQuery {
    top: file(relativePath: { eq: "sports-break.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    meetSellars: file(relativePath: { eq: "gavin.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meetGavin: file(relativePath: { eq: "gavin2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    team: file(relativePath: { eq: "tony.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sewing: file(relativePath: { eq: "jp-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
